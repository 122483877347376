import { createContext, useContext } from 'react';
import axiosConnection from '../config/axios';

const AuthenticationContext = createContext(null);

export const AuthenticationProvider = ({ children }) => {

    const signOut = async () => {

        localStorage.removeItem("token");
        await axiosConnection.post('/api/auth/sign-out');

    };

    const signIn = (token) => {
        localStorage.setItem('token', token);
    };

    const validateToken = async () => {

        try {

            await axiosConnection.post('/api/auth/token/verify');

        } catch (error) {
            signOut();
            console.error(error.message);
        }

    };

    validateToken();

    return (
        <AuthenticationContext.Provider
            value={{
                signOut,
                signIn
            }}
        >
            {
                children
            }
        </AuthenticationContext.Provider>
    );

};

export const useAuthentication = () => {
    return useContext(AuthenticationContext);
};
