import { LoadingOutlined } from '@ant-design/icons'
import { Typography } from 'antd';

const { Title } = Typography;

const Loader = () => {
    return (
        <Title level={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 20, height: '100%', width: '100%', margin: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>Cargando...<LoadingOutlined /></Title>
    )
}

export default Loader