import axiosConnection from '../config/axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../config/api';
import dayjs from 'dayjs'
import { Col, Row } from 'antd';
import '../styles/Main.css';

const Main = () => {

    const { uid } = useParams();
    const [licenseState, setLicenseState] = useState(null);

    const fetchLicenseOnReady = async () => {

        try {

            const { data: response } = await axiosConnection.get(`/api/license/get/${uid}`);
            const { data } = response;

            setLicenseState(data);

        } catch (error) {
            alert(error.message);
            console.error(error);
        }

    };

    useEffect(() => {

        if (uid) {
            fetchLicenseOnReady();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row
                wrap
                className='main'
            >
                <Col
                    span={8}
                    xs={24}
                    md={8}
                >
                    {
                        uid ? (
                            <div
                                className='card-box'
                            >
                                <div className='card-box-head'>
                                    <span>DATOS PERSONALES</span>
                                </div>
                                <div className='card-box-body'>
                                    <img
                                        className='card-box-image'
                                        src={
                                            licenseState ? (
                                                `${API_URL}/api/image/get/${licenseState?.image}`
                                            ) : (
                                                "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/corporate-user-icon.png"
                                            )
                                        }
                                        alt=""
                                    />
                                    <div className='card-box-license'>

                                        <div className='license-group'>
                                            <label htmlFor="">Folio:</label>
                                            <span>{licenseState?.folio.toUpperCase()}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">Tipo:</label>
                                            <span>{licenseState?.type.toUpperCase()}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">Nombre:</label>
                                            <span>{licenseState?.name.toUpperCase()}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">Apellidos:</label>
                                            <span>{licenseState?.surname.toUpperCase()}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">CURP/RFC:</label>
                                            <span>{licenseState?.uniqueKey.toUpperCase()}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">Expiración:</label>
                                            <span>{dayjs(licenseState?.issue).format('YYYY-MM-DD')}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">Vencimiento:</label>
                                            <span>{dayjs(licenseState?.expires).format('YYYY-MM-DD')}</span>
                                        </div>

                                        <div className='license-group'>
                                            <label htmlFor="">Vigencia:</label>
                                            <span>{licenseState?.validity} AÑOS</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                className='card-box-empty'
                                style={{
                                    height: 80
                                }}
                            >
                                <div className='card-box-head'>
                                    <span>DATOS PERSONALES</span>
                                </div>
                                <div className='card-box-body'>
                                    <b style={{ fontWeight: 'bolder', fontSize: '0.9rem' }}>No se localizo información de ninguna licencia</b>
                                </div>
                            </div>
                        )
                    }

                </Col>

                <Col
                    span={12}
                    xs={24}
                    md={12}
                >
                    <div
                        className='card-box'
                        style={{
                        }}
                    >
                        <h1 className='validation-msg'>Validación de Licencias de Conducir</h1>
                        <img className='driver-license' src={`${API_URL}/api/image/get/travel_12979704.png`} alt="" />
                    </div>
                </Col>

                <img
                    alt=""
                    src={`${API_URL}/api/image/get/cdmxLogo.png`}
                    style={{
                        width: '100%',
                        filter: 'opacity(70%)'
                    }}
                />

            </Row>
        </>
    )
}

export default Main