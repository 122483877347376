import React, { useState } from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Space, Form, Typography } from 'antd'
import axiosConnection from '../config/axios';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../context/AuthenticationContext';

const { Title } = Typography;

const Login = () => {

    const { signIn } = useAuthentication();
    const [isLoading] = useState(false);
    const [LoginForm] = Form.useForm();
    const navigate = useNavigate();

    const onSubmit = async () => {

        try {

            const { username, password } = LoginForm.getFieldsValue()
            const { data: response } = await axiosConnection.post('/api/auth/sign-in', { username, password });
            await signIn(response.token);

            alert('Acceso correcto, redirigiendo...');

            setTimeout(() => {
                navigate('/admin')
            }, 2000);

        } catch (error) {
            alert(error.response.data.msg);
            console.error(error);
        }

    };

    return (
        <Row>
            <Col span={24}>
                <Card
                    hoverable
                    style={{
                        margin: 'auto',
                        marginTop: 80,
                        maxWidth: 340,
                    }}
                >
                    <Title level={3}>Inicia sesión</Title>
                    <Form
                        size='large'
                        form={LoginForm}
                        onFinish={onSubmit}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor llena el campo requerido!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} placeholder='Username' />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor llena el campo requerido!',
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} placeholder='Ingresa tu contraseña' />
                        </Form.Item>
                        <Space>
                            <Button loading={isLoading} type="primary" htmlType="submit">Iniciar Sesión</Button>
                        </Space>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}

export default Login;
