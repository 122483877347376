/* eslint-disable react/display-name */
import { Component } from 'react';
import { Navigate } from 'react-router-dom';

const IsAdmin = (Page) => {

    return class extends Component {

        render() {

            const token = localStorage.getItem("token");
            if (!token) return <Navigate to='/login' />;

            return token ? <Page /> : <Navigate to="/404" replace />

        }
    }
};

export default IsAdmin;
