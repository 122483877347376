import React, { useState } from 'react'
import { Col, Layout, Menu, Row } from 'antd';
import { Outlet } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import Loader from '../components/Loader';

const { Header, Content } = Layout;

const AdminLayout = () => {

    const [current, setCurrent] = useState('/admin');

    const indexPath = [
        {
            label: "Admin",
            key: '/admin',
            icon: <UserOutlined />,
        },
    ];

    const onClick = (e) => {
        setCurrent(e.key);
    };

    return (
        <Layout>
            <Header>
                <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" style={{ width: '100%', height: '100%' }} theme='dark' items={indexPath} />
            </Header>
            <Content className='app-content'>
                <React.Suspense fallback={<Loader />}>
                    <Row
                        wrap={false}
                        style={{
                            backgroundColor: '#e6e6e6',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            minHeight: '100%',
                            boxSizing: 'border-box',
                            padding: 16
                        }}
                    >
                        <Col span={24} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 16,
                        }}>
                            <Outlet />
                        </Col>
                    </Row>
                </React.Suspense>
            </Content>
        </Layout>
    )
}

export default AdminLayout