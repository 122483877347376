import { Routes, Route, Navigate } from "react-router-dom";
import { AuthenticationProvider } from "./context/AuthenticationContext";
import AdminLayout from './layout/AdminLayout';
import AdminRoutes from './routes/Admin';
import Index from './views/Index';
import Login from './views/Login';

const createLazyRoutes = (routes) => routes.map(({ path, component }, key) => <Route exact path={path} element={component} key={key} />); //? FUNCTION TO BUILD ROUTES

function App() {

    return (
        <Routes>
            <Route path="*" element={<Navigate to="/validacionqr/license/qr/check" />} />
            <Route path='/404' element={<h1>404 Not Found</h1>} />
            <Route path='/validacionqr/license/qr/check/:uid?' element={<Index />} />
            <Route
                path="/login"
                element={
                    <AuthenticationProvider>
                        <Login />
                    </AuthenticationProvider>
                }
            />
            <Route element={<AdminLayout />}>
                <Route path='/admin'>
                    {
                        createLazyRoutes(AdminRoutes)
                    }
                </Route>
            </Route>
        </Routes>
    )
}

export default App
