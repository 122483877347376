import React from 'react';
import IsAdmin from '../HOC/AdminValidation';
import { AuthenticationProvider } from "../context/AuthenticationContext";

const Admin = IsAdmin(React.lazy(() => import('../views/Admin/Admin')));
const AdminForm = IsAdmin(React.lazy(() => import('../views/Admin/Admin.Form')));

const routes = [
    {
        path: '/admin',
        component: <AuthenticationProvider>
            <Admin />
        </AuthenticationProvider>
    },
    {
        path: '/admin/user/:user?',
        component: <AuthenticationProvider>
            <AdminForm />
        </AuthenticationProvider>
    }
];

export default routes;  
