import axios from 'axios';
import { API_URL } from './api';

const axiosConnection = axios.create({
    baseURL: API_URL,
    withCredentials: true,
});

axiosConnection.interceptors.request.use(function (config) {

    const token = localStorage.getItem('token');

    config.headers["Authorization"] = token;

    return config;

});

export default axiosConnection;
