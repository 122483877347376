import * as React from 'react';
import { API_URL } from '../config/api';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Main from './Main';
import '../styles/Navbar.css';

function Navbar() {

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                style={{
                    boxShadow: '0 0 3px rgba(0, 0, 0, 0.3)'
                }}
            >
                <div className='navbar'>
                    <img src={`${API_URL}/api/image/get/logo.png`} height="55" alt='' />
                    <span
                        style={{
                            color: 'black',
                            fontSize: 12,
                        }}
                    >CDMX</span>
                </div>
            </AppBar>
            <Main />
        </Box>
    );
}

Navbar.propTypes = {
    window: PropTypes.func,
};

export default Navbar;
